import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout/Container"

export default () => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/contactUs__complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const complete = data.complete.childImageSharp.fluid

  return (
    <Layout seoTitle="Submission Completed">
      <Container isCentered>
        <center>
          <h1 className="title my-2">Thank you!</h1>
          <Container isCentered mobile={8} tablet={6} desktop={4}>
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1">
            A MedGrocer representative will contact you through SMS or email. If
            you don't receive an SMS within the next working day, please contact
            us at{" "}
            <a
              href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`}
              className="has-text-weight-bold"
            >
              {process.env.GATSBY_AWS_SES_EMAIL_TO}
            </a>
            .
          </p>
          <Link to="/" className="button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}
